.Toastify__toast--default {
  background-color: #fff;
}

.Toastify__progress-bar {
  background: #007aff;
}

.Toastify__toast--info {
  background-color: #fff;
}
.Toastify__toast--success {
  background-color: #fff;
}
.Toastify__toast--warning {
  background-color: #fff;
}
.Toastify__toast--error {
  background-color: #fff;
}
.Toastify__toast-body {
  line-height: 1.5715;
  color: black;
  font-size: 14px;
}

.alert-body {
  display: flex;
  align-items: center;
}
