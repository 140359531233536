.App {
  max-width: 800px;
  margin: 0 auto;
}

.App-logo {
  pointer-events: none;
  margin: 1rem 0;
}

.App-header {
  border: 2px solid #282c34;
  color: #282c34;
}

nav {
  display: flex;
  justify-content: space-between;
}

.links {
  list-style: none;
}

.links > * {
  margin: 2em;
}

.links a {
  margin-right: 2em;
  font-size: 1.5rem;
  cursor: pointer;
}

.links a:hover {
  border-bottom: 1px solid navy;
}

.route-title {
  font-size: 2rem;
}

.table-row {
  cursor: pointer;
}

table.multifirm {
  margin-top: 1%;
}

.userforms .table-row {
  cursor: default;
}

.userforms .table-row > button {
  cursor: pointer;
}
.table-row:hover {
  background-color: rgba(0, 0, 0, 0.089);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spinner .ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.spinner .ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid;
  border-color: #0d4f94 transparent #0d4f94 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* "Globals" */
.text-align-right {
  text-align: right !important;
}

.data-grid-container td span.value-viewer {
  text-align: right;
}
.data-grid-container td.read-only span.value-viewer {
  text-align: center;
}

/* MUI overrides TODO move into theme */

#root label + .MuiInput-formControl {
  padding: 0px;
  margin-bottom: 0px !important;
  margin-top: 0px;
}


/*
Hide input:number arrows
https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/*
react-datasheet overrides
*/
#root .data-grid {
  width: 100%;
}

#root .data-grid-container .data-grid .cell {
  height: inherit;
  text-align: center;
}

#root .data-grid-container .data-grid .cell > input {
  width: 100%;
  height: 20px;
}

.modal {
  position: absolute;
  width: 900px;
  border: 2px solid #000;
  padding: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  max-height: 800px;
  overflow-y: scroll;
}

.modal .footer {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

.modal .footer button {
  margin: 0px 5px;
}

.flag {
  top: 2px;
  right: 0;
  padding: 1px 8px;
  border-radius: 3px;
  background-color: #0091f9;
  color: white;
  font-size: 12px;
  font-weight: 700;
  vertical-align: super;
  margin-left: 1%;
}

a.fileDownload_button {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #e0e0e0;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
}

a.fileDownload_button:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #d5d5d5;
  text-decoration: none;
  color: inherit;
}

#root .MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 2px;
  margin-bottom: 0 !important;
}


